export const formatIconWeather = (iconName: number): string => {
  switch (iconName) {
    case 1:
      return "cloud";
    case 2:
      return "cloud_and_sun";

    default:
      return "";
  }
};
